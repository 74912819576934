import { Example } from "./Example";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {
        text: "介绍一下卡萨帝品牌",
        value: "介绍一下卡萨帝品牌"
    },
    { text: "如何安装海尔智家客户端", value: "如何安装海尔智家客户端" },
    { text: "卡萨帝七星级服务具体都是什么?", value: "卡萨帝七星级服务具体都是什么?" }
];

interface Props {
    onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {EXAMPLES.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
